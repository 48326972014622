@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;1,300&display=swap");

.wrapNavy {
  background: #05204a;
  color: white;
  position: relative;
}

.wrapWht {
  background: #e4e4e4;
  color: black;
}

.mainSection:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background: url("../../assets/imgs/newport-beach.jpg");
  background-repeat: no-repeat;
  background-position: 50% 30%;
  background-size: cover;
}

.mainSection .container {
  position: relative;
  /* z-index: 999; */
}

.mainSection .container::after {
  content: " ";
  background: url("../../assets/imgs/corner-cap.png") no-repeat;
  height: 200px;
  width: 50%;
  top: 2rem;
  right: 2rem;
  position: absolute;
  z-index: 1;
}

.mainImg {
  z-index: 999;
}

.mainImg img {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}

.retirementWorthLivingSection .container,
.eventSection .container,
.ourServicesSection .container {
  position: relative;
  z-index: 999;
}

.retirementWorthLivingSection img,
.retirementWorthLivingSection p,
.retirementWorthLivingSection h1,
.eventSection img,
.eventSection p,
.eventSection h1 {
  z-index: 999;
  position: relative;
}

.worthLivingMainImg img {
  width: 100%;
}

.eventsMainImg img {
  width: 100%;
}

.worthLivingImages,
.eventImages {
  margin-top: 1.5rem;
}

.eventImages>div>img:hover,
.worthLivingImages>div>img:hover {
  cursor: pointer;
}

.worthLivingImages div:nth-child(3),
.eventImages div:nth-child(3) {
  margin-bottom: 1.5rem;
}

.serviceList {
  gap: 2rem;
  display: flex;
  flex-direction: column;
  background-color: #05204a;
  color: white;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.ourServicesFeatures {
  z-index: 4;
}

.footerSection p {
  color: gray;
}

.two-columns__col-one,
.two-columns__col-two {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin: 0;
  padding: 1rem;
}

.two-columns__row-one,
.two-columns__row-two,
.two-columns__row-three {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  margin: 0;
  padding: 1rem;
}

.two-columns__row-two {
  padding: 0 1rem;
}


.section-event-details__row {
  display: flex;
  flex-direction: column;
  margin: 0;
  /* background-color: rgb(218, 218, 218); */
  /* border-radius: 0.5rem; */
  /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3); */
}

.column {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media screen and (min-width: 992px) {
  h1.large {
    font-size: 4rem;
  }

  .mainSection .container {
    padding: 1.5rem 0rem;
  }

  .mainSection .container::after {
    content: " ";
    background: url("../../assets/imgs/corner-cap.png") no-repeat;
    height: 200px;
    width: 50%;
    top: 1.5rem;
    right: 0;
    position: absolute;
    z-index: 1;
  }

  .crossIcon {
    margin-top: 2rem;
  }

  .crossIconExt {
    height: 57px;
    position: absolute;
    left: -6rem;
    top: 4rem;
  }

  .worthLivingMainImg img {
    margin: 2rem;
  }

  .worthLivingImages {
    margin-top: 2rem;
  }

  .worthLivingMainImg img {
    margin: 2rem;
    width: 100%;
  }

  .workingWithClients {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .ourServicesSection .container::before {
    content: " ";
    position: absolute;
    border: 2px solid rgb(179, 179, 179);
    height: 80%;
    left: 0;
    right: 0;
    margin: 6rem 0rem 6rem 5rem;
    z-index: 2;
    top: 0;
  }

  .two-columns__col-one {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .two-columns__col-two {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .container {
    padding: 4rem 0rem;
  }

}