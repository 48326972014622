.navbar {
    position: relative;
    z-index: 999;
}

.desktopMenu {
    display: flex;
    align-items: center;
    height: 4rem;
    position: relative;
    background-color: transparent;    
    z-index: 999;
    box-sizing: content-box;
}

.mobileMenu {
    display: flex;
    padding: 1rem;
}

.brand {
    flex: 2;
}

.brand img {
    height: 40px;
    width: auto;
    justify-content: flex-start;
}
.mobileMenu {
    display: flex;
    align-items: center;
}
.navMenu {
    display: flex;
    flex-direction: column;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
    background-color: transparent;
    padding: 0;      
    list-style: none;  
    position: absolute;
    width: 100%;
}
.navMenu.active {
    opacity: 1;
    left: 0;
    transition: all 0.5s ease;
    z-index: 1;
    list-style: none;
    background-color: rgba(0, 0, 0, .9);
}
.navLinks {
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    display: table;
    color: white;
    text-decoration: none;
}
.navLinks:hover {
    border-radius: 0;
}
.menuIcon {
    font-size: 1.8rem;
    cursor: pointer;
}
.faBars{
    color: black;
}
.faTimes {
    font-size: 24px;
    color: black;
}

// applied css rules for screen width over 992px

@media screen and (min-width: 992px) {
    .navbar {
        background-color: transparent;
        justify-content: center;
        width: 70vw;
        margin: auto;
         padding: 0;
    }
    .navMenu {
        display: flex;
        flex-direction: row;
        left: 0;
        position: relative;
        opacity: 1;
        transition: all 0.5s ease;
        background-color: transparent;
        padding: 0;       
        margin: 0;
        width: inherit;
    }    
    .navLinks {
        color: white;
        padding: 0.5rem 1rem;
    }
    .desktopLogo {
        display: block;
    }
    .mobileLogo {
        display: none;
    }
    .menuIcon {
        display:none;
    }    
    .brand {
     flex: 2;   
    }
}

